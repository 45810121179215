@import "../src/Components/Variables/variables.css";

body {
  color: var(--color-text-dark);
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}